.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: auto;
  padding: 0 40px;
  color: $white !important;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px;
  text-transform: uppercase;
  transition: all ease-out .5s;
  background-color: $blue;
  border: none !important;
  outline: none !important;
  &-blue {
    background-color: $blue; }
  &-white {
    background-color: $white;
    color: $blue !important;
    border: 2px solid $white !important;
    &:hover {
      color: $white !important;
      background-color: transparent !important; } }
  &:hover {
    background-color: $v100; } }
