/* Base */

html {
  font-family: $basic;
  font-weight: normal;
  font-size: 100%;
  font-display: swap; }
body {
  width: 100%;
  max-width: 100% !important;
  color: $dark;
  font-family: $basic;
  font-weight: normal;
  font-smooth: always;
  overflow-x: hidden;
  -webkit-font-smoothing: antialised;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  overflow-x: hidden; }

* {
  letter-spacing: .025em !important; }

a, * a {
  color: inherit !important;
  text-decoration: none !important; }

footer {
  margin-top: 40px;
  padding: 40px 0 38px;
  background-color: darken($dark, 10%);
  * {
    color: #eeeeee !important; }
  @include v-xs {
    .text-left, .text-right {
      text-align: center !important; } } }

section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

* {
  font-size: 1em; }

@keyframes colorAnimation {
  0% {
    color: rgb(134, 158, 235); }
  50% {
    color: rgb(229, 166, 245); }
  75% {
    color: rgb(166, 150, 244); }
  100% {
    color: rgb(134, 158, 235); } }

.bgc {
  animation: colorAnimation 10s linear infinite; }
