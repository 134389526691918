.page {
  padding: 10vh;
  * {
    color: #fff; }
  &-content {
    * {
      font-size: 1.25em;
      color: #444;
      font-weight: 500;
      line-height: 1.75;
      padding-bottom: 20px; }
    a {
      font-size: 1em;
      color: $theme !important; }
    ul {
      list-style: circle; }
    li {
      font-size: 1em; } } }

.e-white {
  color: $white !important; }

.e-v80 {
  color: $v80 !important; }
.e-v100 {
  color: $v100 !important; }
.e-v60 {
  color: $v60 !important; }

.c-circle {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $white;
  margin-right: 12px;
  margin-top: 24px;
  border-radius: 50%;
  background-color: $blue; }

.table.table-striped {
  th, td {
    height: 80px;
    vertical-align: middle; } }

.wh-400 {
  font-weight: 400 !important;
  line-height: 1.75 !important; }

.card {
  border-width: 10px !important;
  border-color: #2c1d46 !important;
  border-radius: 20px !important;
  * {
    border-color: #2c1d46 !important; }
  &.card-pricing {
    min-height: 120vh;
    &.m-0 {
      min-height: 123.5vh; }
    .card-footer {
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      padding-bottom: 20px;
      .button {
        width: 100%;
        padding-top: 3px 0 0 3px !important;
        font-weight: 500; } }
    p {
      font-weight: 400 !important;
      border-top: 1px dashed #2c1d46; } } }

#accordion {
  .card {
    border: none !important; } }

.row {
  .border-left {
    position: relative;
    &::after {
      display: block;
      width: 2px;
      height: 100%;
      display: block;
      position: absolute;
      left: -8.4%;
      top: 64px;
      content: '';
      background-color: $blue; } } }

.bg-top {
  background-color: $blue; }
