$red: #FA7375;
$light: #EDEDED;
$lite: #fdfdfd;
$grey: #B9B9B9;
$page: #515151;
$card: #18191e;
$theme: #1c324c;
$blue: #3fa7de;
$marine: #21a6b5;
$black: #000;
$back: #191b20;
$line: #0a1c39;
$btn: #fefefe;
$yellow: #bc7014;
$logo: #7CD9E5;

$price: #0C8EC5;
$green: #7c8a21;

$pink: #D62C6D;
$dark: #11111D;

$v60: #A5A6F6;
$v80: #7879F1;
$v100: #5D5FEF;

$p60: #FCDDEC;
$p80: #F178B6;
$p100: #EF5DA8;

$white: #fff;

$bgrd: linear-gradient(0deg, #0078D4 0.31%, #1380DA 16.23%, #3C91E5 53.05%, #559CEC 81.9%, #5EA0EF 99.81%);
$cgrd: linear-gradient(180deg, rgba(17, 21, 42, 0.5) 0%, rgba(17, 22, 45, 0.5) 100%);
$rgrd: radial-gradient(100% 100% at 50% 0%, rgba(54, 31, 92, 0.5) 0%, rgba(11, 53, 83, 0.5) 100%);

$basic: 'Inter Tight', sans-serif;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }
