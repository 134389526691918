.title {
  text-transform: uppercase;
  color: $dark;
  font-weight: 700;
  line-height: 1.75;
  font-size: 1.75em;
  &-big {
    font-size: 3.75em; }
  &-large {
    font-size: 2.25em; }
  &-medium {
    font-size: 2em; }
  &-small {
    font-size: 1.25em; } }
